//         ,''',
//        .' ., .',                                  ../'''',
//        .'. %%, %.',                            .,/' .,%   :
//       .'.% %%%,`%%%'.    .....,,,,,,.....   .,%%% .,%%'. .'
//       : %%% %%%%%%',:%%>>%>' .,>>%>>%>%>>%%>,.   `%%%',% :                         ||```````````````````````````||
//       : %%%%%%%'.,>>>%'   .,%>%>%'.,>%>%' . `%>>>,. `%%%:'                         ||                           ||
//       ` %%%%'.,>>%'  .,%>>%>%' .,%>%>%' .>>%,. `%%>>,. `%                          ||      ΠΡΟΣΟΧΗ: ΝΕΥΡΙΑΖΕΙ   ||
//        `%'.,>>>%'.,%%%%%%%' .,%%>%%>%' >>%%>%>>%.`%% %% `,                         ||     ΑΝ ΑΚΟΥΜΠΗΣΕΤΕ ΤΟΝ    ||
//        ,`%% %%>>>%%%>>%%>%%>>%>>%>%%%  %%>%%>%%>>%>%%%' % %,                       ||     ΚΩΔΙΚΑ Ή ΤΟ ΦΑΙ ΤΟΥ   || 
//      ,%>%'.>>%>%'%>>%%>%%%%>%'                 `%>%>>%%.`%>>%.                     ||                           ||
//    ,%%>' .>%>%'.%>%>>%%%>>%' ,%%>>%%>%>>%>>%>%%,.`%%%>%%. `%>%.                    ===============================
//   ` ,%' .>%%%'.%>%>>%' .,%%%%%%%%'          `%%%%%%.`%%>%% .%%>
//    .%>% .%%>' :%>>%%'.,%%%%%%%%%'.%%%%%' `%%%%.`%%%%%.%%%%> %%>%.
//   ,%>%' >>%%  >%' `%%%%'     `%%%%%%%'.,>,. `%%%%'     `%%%>>%%>%
//  .%%>%' .%%>'  %>>%, %% oO ~ Oo %%%>>'.>>>>>>. `% oO ~ Oo'.%%%'%>%,
//   %>'%> .%>%>%  %%>%%%'  `OoooO'.%%>>'.>>>%>>%>>.`%`OoooO'.%%>% '%>%
//   %',%' %>%>%'  %>%>%>% .%,>,>,   `>'.>>%>%%>>>%>.`%,>,>' %%%%> .>%>,
//   ` %>% `%>>%%. `%%% %' >%%%%%%>,  ' >>%>>%%%>%>>> >>%%' ,%%>%'.%%>>%.
//   .%%'  %%%%>%.   `>%%. %>%%>>>%.>> >>>%>%%%%>%>>.>>>'.>%>%>' %>>%>%%
//   `.%%  `%>>%%>    %%>%  %>>>%%%>>'.>%>>>>%%%>>%>>.>',%>>%'  ,>%'>% '
//    %>'  %%%%%%'    `%%'  %%%%%> >' >>>>%>>%%>>%>>%> %%>%>' .%>%% .%%
//   %>%>, %>%%>>%%,  %>%>% `%%  %>>  >>>%>>>%%>>>>%>>  %%>>,%>%%'.%>%,
//    %>%>%%, `%>%%>%>%, %>%%> ,%>%>>>.>>`.,.  `"   ..'>.%. % %>%>%'.%>%%;
//    %'`%%>%  %%>%%  %>% %'.>%>>%>%%>>%::.  `,   /' ,%>>>%>. >%>%'.%>%'%'
//    ` .%>%'  >%%% %>%%'.>%>%;''.,>>%%>%%::.  ..'.,%>>%>%>,`%  %'.>%%' '
//    %>%>%% `%>  >%%'.%%>%>>%>%>%>>>%>%>>%,,::,%>>%%>%>>%>%% `>>%>'
//     %'`%%>%>>%  %>'.%>>%>%>>;'' ..,,%>%>%%/::%>%%>>%%,,.``% .%>%%
// `    `%>%>>%%' %>%%>>%>>%>%>%>%%>%/'       `%>%%>%>>%%% ' .%'
//       %'  `%>% `%>%%;'' .,>>%>%/',;;;;;,;;;;,`%>%>%,`%'   '
//        `    `  ` `%>%%%>%%>%%;/ @a;;;;;;;;;;;a@  >%>%%'                  //////////////////////////////////////////////
//                   `/////////';, `@a@@a@@a@@aa@',;`//'                    // Περιγραφή: ΡΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ-
//                       `//////.;;,,............,,;;//'                    // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                         `////;;;;;;;;;;;;;;;;;/'                         // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ 
//                            `/////////////////'                           // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                                                                          // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΡΡ
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////                 

import { ThemeContext, LangContext, MenuContext, Page, RegionContext, Theme, ThemeDark, ThemeLight, _Nav, AlertContext } from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GuardedRoute } from "../services/authentication/Guard";
import Navbar_ from "../components/navbar/Navbar_";
import { Protector } from "../services/authentication/Protector";
import TRANSLATIONS from "../admin/views/translations/TRANSLATIONS";
import PANEL from "../admin/views/panel/PANEL";
import NO_ACCESS from "../admin/views/noAccess/NO_ACCESS";
import { init, reconstructUrl } from "../helpers/initMenuOptions";
import { useSelector } from "react-redux";
import ENDPOINTS from "../pages/endpoints/ENDPOINTS";
import QUESTIONNAIRE_A from "../pages/questionnaireA/QUESTIONNAIRE_A";
import QUESTIONNAIRE_B from "../pages/questionnaireB/QUESTIONNAIRE_B";
import REDIRECT_AFTER_LOGIN from "../pages/redirectAfterLogin/REDIRECT_AFTER_LOGIN";
import LANDING_BUILDER from "../pages/landingBuilder/LANDING_BUILDER";
import { LandingContext } from "../context/LandingContext";
import CONNECT_FACEBOOK from "../pages/connectFacebook/CONNECT_FACEBOOK";
import FACEBOOK_PAGE from "../pages/facebookPage/FACEBOOK_PAGE";
import LANDING_PAGES from "../pages/landingPages/LANDING_PAGES";
import AD_PAGES from "../pages/adPages/AD_PAGES";
import FB_PAGES_NOT_FOUND from "../pages/fbPagedNotFound/FB_PAGES_NOT_FOUND";
import CREATE_AD from "../pages/createAd/CREATE_AD";
import PREVIEW_AD from "../pages/previewAd/PREVIEW_AD";
import LANDING_VIEW from "../pages/landingView/LANDING_VIEW";
import PAGE_NOT_FOUND from "../pages/pageNotFound/PAGE_NOT_FOUND";
import DASHBOARD from "../pages/dashboard/DASHBOARD";
import EDIT_QUEST from "../pages/editQuest/EDIT_QUEST";
import EDIT_QUESTIONNAIRE_B from "../pages/editQuestionnaireB/EDIT_QUESTIONNAIRE_B";
import EDIT_LANDING from "../pages/editLanding/EDIT_LANDING";
import EDIT_AD from "../pages/editAd/EDIT_AD";
import CANCEL_SUBSCRIPTION from "../pages/cancelSubscription/CANCEL_SUBSCRIPTION";
import FACEBOOK_BUSINESS from "../pages/facebookBusiness/FACEBOOK_BUSINESS";
import FACEBOOK_AD_ACCOUNT from "../pages/facebookAdAccount/FACEBOOK_AD_ACCOUNT";
import LANDING_HELPER from "../pages/landingHelper/LANDING_HELPER";
import AD_HELPER from "../pages/adHelper/AD_HELPER";
import HOME from "../pages/home/HOME";
import ESHOP_CONFIGURE from "../pages/eshopConfigure/ESHOP_CONFIGURE";
import REDIRECT_ESHOP_URL from "../pages/redirectEshopUrl/REDIRECT_ESHOP_URL";
import FORM_LEADS from "../pages/formLeads/FORM_LEADS";
import SUCCESS_PAYMENT from "../pages/SUCCESS_PAYMENT.js/SUCCESS_PAYMENT";
import USERS from "../admin/views/users/USERS";
import USER_QUESTS from "../admin/views/userQuests/USER_QUESTS";
import { LocationsContext } from "../context/LocationsContext";
import { PocketAgencyContext } from "../context/PocketAgencyContext";
import DASHBOARD_ from "../pages/dashboard/DASHBOARD_";
import THANK_YOU from "../pages/thankYou/THANK_YOU";
import AD_ACCOUNTS from "../pages/adAccounts/AD_ACCOUNTS";
import NEW_QUEST from "../pages/newQuest/NEW_QUEST";

export default function Dailyprofit() {

    const user = useSelector(state => state.user.user)

    const navigate = useNavigate()
    const location = useLocation();
    
    const [theme, setTheme] = useState(); 
    const [language, setLanguage] = useState(); 
    const [region, setRegion] = useState(); 
    const [locationsCtx, setLocationsCtx] = useState(); 
    const [pocketAgencyContext, setPocketAgencyContext] = useState(); 
    const [activeMenu, setActiveMenu] = useState('Main'); 
    const [alerts, setAlerts] = useState([]);
    const [landing, setLanding] = useState({
        colors: {
            background: "#ffffff", // default background color
            text: "#000000", // default text color
        }, 

        logo: '',
        
        header:{
            title:'',
            subtitle:'',
        },

    
        defaultFormFields:['Name', 'Tel'],
       // customFormField: {visibility: false, label:''},
        customFormField: [{label:'', type: 'input'}],

        img:''
    })

    //const [landing, setLanding] = useState({})

    useEffect(() => {
        if ( reconstructUrl(language) ) navigate(reconstructUrl(language))
    }, [language])


    useEffect(() => {
        init(user, setTheme, setLanguage, setRegion);
    }, [user]);


    return theme && language && region ? (
        <LangContext.Provider value={{language, setLanguage}}>
            <RegionContext.Provider value={{region, setRegion}}>    
                <ThemeContext.Provider value={{theme, setTheme}}>
                    <ThemeProvider theme={theme === 'dark' ? ThemeDark : ThemeLight}>
                        <AlertContext.Provider value={{alerts, setAlerts}}>
                            <LandingContext.Provider  value={{landing, setLanding}}>
                                <LocationsContext.Provider value={{locationsCtx, setLocationsCtx}}>
                                    <PocketAgencyContext.Provider value={{pocketAgencyContext, setPocketAgencyContext}}>
                                        <Theme>
                                            <Page>
                                                <MenuContext.Provider value={{activeMenu, setActiveMenu}}>
                                                    <GuardedRoute>
                                                        <Navbar_ />
                                                    </GuardedRoute>
                                                </MenuContext.Provider>
                                                <Routes>
                                                    <Route path="/" 
                                                        element={<Navigate to={{
                                                        pathname: `/${language}/`}}
                                                        />}
                                                    />
                                                    <Route path="/" element={<GuardedRoute><HOME/></GuardedRoute>} />
                                                    <Route path="/:lang" element={<GuardedRoute><HOME/></GuardedRoute>} />
                                                    <Route path="/:lang/home" element={<HOME/>} />
                                                    <Route path="/:lang/endpoints" element={<Protector role="Scout"><ENDPOINTS/></Protector>} />
                                                    <Route path="/:lang/facebook-login" element={<Protector role="Doer"><CONNECT_FACEBOOK/></Protector>} />
                                                    <Route path="/:lang/choose-facebook-page" element={<Protector role="Doer" checkAgencyClient={true}><FACEBOOK_PAGE/></Protector>} />
                                                    <Route path="/:lang/choose-facebook-page/:questId" element={<Protector role="Doer"><FACEBOOK_PAGE/></Protector>} />
                                                    <Route path="/:lang/choose-webpage" element={<Protector role="Doer"><LANDING_PAGES/></Protector>} />
                                                    <Route path="/:lang/choose-webpage/:questId" element={<Protector role="Doer"><LANDING_PAGES/></Protector>} />
                                                    <Route path="/:lang/choose-ad" element={<Protector role="Doer"><AD_PAGES/></Protector>} />
                                                    <Route path="/:lang/choose-ad/:questId" element={<Protector role="Doer"><AD_PAGES/></Protector>} />
                                                    <Route path="/:lang/choose-facebook-business" element={<FACEBOOK_BUSINESS/>} />
                                                    <Route path="/:lang/choose-facebook-ad-account" element={<FACEBOOK_AD_ACCOUNT/>} />
                                                    <Route path="/:lang/ad-accounts" element={<AD_ACCOUNTS/>} />


                                                    <Route path="/:lang/facebook-pages-not-found" element={<FB_PAGES_NOT_FOUND/>} />
                                                    <Route path="/:lang/questionnaire/alpha" element={<GuardedRoute><QUESTIONNAIRE_A/></GuardedRoute>} />
                                                    <Route path="/:lang/success-payment" element={<Protector role="Doer"><SUCCESS_PAYMENT /></Protector>}/>
                                                    <Route path="/:lang/new-quest/:questId" element={<Protector role="Doer"><NEW_QUEST /></Protector>}/>

                                                    <Route path="/:lang/redirect-to-eshop/:questId" element={<REDIRECT_ESHOP_URL/>} />
                                                    <Route path="/:lang/create-ad" element={<Protector role="Doer" checkAgencyClient={true}><CREATE_AD/></Protector>} />
                                                    <Route path="/:lang/ad-helper" element={<Protector role="Doer"><AD_HELPER/></Protector>} />
                                                    <Route path="/:lang/preview-ad" element={<Protector role="Doer" checkAgencyClient={true}><PREVIEW_AD/></Protector>} />
                                                    <Route path="/:lang/preview-ad/:questId" element={<Protector role="Doer" checkAgencyClient={true}><PREVIEW_AD/></Protector>} />
                                                
                                                    <Route path="/:lang/webpage" element={<LANDING_VIEW />} />
                                                    <Route path="/:lang/landing" element={<Navigate to={{ 
                                                        pathname: "/:lang/webpage",
                                                        search: location.search 
                                                    }} />} />
                                                    <Route path="/:lang/webpage-helper" element={<LANDING_HELPER />} />
                                                    <Route path="/:lang/thank-you" element={<THANK_YOU />} />
                                                    
                                                    <Route path="/:lang/dashboard/" element={<Protector role="Doer" checkAgencyClient={true}><DASHBOARD/></Protector>} />
                                                    <Route path="/:lang/dashboard/subscriptions/:questId" element={<Protector role="Doer"><DASHBOARD/></Protector>} />
                                                    <Route path="/:lang/dashboard2/" element={<Protector role="Doer"><DASHBOARD_/></Protector>} />
                                                    <Route path="/:lang/form-leads/:questId" element={<Protector role="Doer"><FORM_LEADS/></Protector>} />
                                                    <Route path="/:lang/edit-quest/:questId" element={<Protector role="Doer"><EDIT_QUEST/></Protector>} />
                                                    <Route path="/:lang/questionnaires/beta/edit/:questId" element={<Protector role="Doer" checkAgencyClient={true}><EDIT_QUESTIONNAIRE_B/></Protector>} />
                                                    <Route path="/:lang/edit-webpage/:questId" element={<Protector role="Doer"><EDIT_LANDING/></Protector>} />
                                                    <Route path="/:lang/edit-ad/:questId" element={<Protector role="Doer"><EDIT_AD/></Protector>} />
                                                    <Route path="/:lang/cancel-subscription/:questId" element={<Protector role="Doer"><CANCEL_SUBSCRIPTION/></Protector>} />
                                                    {/* ADMIN */}
                                                    <Route path="/:lang/akihiko/dailyprofit" element={<Protector role="Knight"><PANEL/></Protector>} /> 
                                                    <Route path="/:lang/akihiko/dailyprofit/translations" element={<Protector role="Knight"><TRANSLATIONS/></Protector>} />
                                                    <Route path="/:lang/akihiko/dailyprofit/users" element={<Protector role="Knight"><USERS/></Protector>} />
                                                    <Route path="/:lang/akihiko/dailyprofit/user" element={<Protector role="Knight"><USER_QUESTS/></Protector>} />
                                                    {/* MISC */}
                                                    <Route path="/:lang/access-denied" element={<NO_ACCESS/>} /> 
                                                    <Route path="/:lang/page-not-found" element={<PAGE_NOT_FOUND/>} /> 
                                                    <Route path="/:lang/questionnaire/beta" element={<Protector role="Doer" checkAgencyClient={true}><QUESTIONNAIRE_B/></Protector>} />
                                                    <Route path="/:lang/webpage-setup" element={<Protector role="Doer"><LANDING_BUILDER /></Protector>} />
                                                    <Route path="*" element={<PAGE_NOT_FOUND/>} />

                                                    {/* ESHOP */}

                                                    <Route path="/:lang/configure-eshop" element={<ESHOP_CONFIGURE/>} /> 
                                                    <Route path="/:lang/configure-eshop/:questId" element={<ESHOP_CONFIGURE/>} /> 
                                                    <Route path="/:lang/redirect" element={<REDIRECT_AFTER_LOGIN/>} />
                                                </Routes>
                                            </Page>
                                        </Theme>   
                                    </PocketAgencyContext.Provider>
                                </LocationsContext.Provider>
                            </LandingContext.Provider>
                        </AlertContext.Provider>
                    </ThemeProvider>
                </ThemeContext.Provider>
            </RegionContext.Provider>
        </LangContext.Provider> 
    ) : <></>;
}